// extracted by mini-css-extract-plugin
export var caseContactFormSection = "bL_k4";
export var caseStudyBackground__lineColor = "bL_kW";
export var caseStudyHead = "bL_k6";
export var caseStudyHead__imageWrapper = "bL_kT";
export var caseStudyProjectsSection = "bL_k5";
export var caseStudyQuote__bgRing = "bL_k1";
export var caseStudyVideo__ring = "bL_k8";
export var caseStudy__bgDark = "bL_kS";
export var caseStudy__bgDarkReverse = "bL_mW";
export var caseStudy__bgLight = "bL_kR";